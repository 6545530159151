






import { Component, Vue } from 'vue-property-decorator';
import { getPrivacyPolicy } from '@/services/forum';

@Component
export default class Privacy extends Vue {
  private privacyContent = '';

  private async mounted() {
    this.privacyContent = await getPrivacyPolicy();
  }
}
