












import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class StaffMember extends Vue {
  @Prop() private memberType!: string;
  @Prop() private teamMember!: any;
}
