






















































































import { Component, Vue } from 'vue-property-decorator';
import HeroIntro from '@/components/HeroIntro.vue';
import HeroFeatures from '@/components/HeroFeatures.vue';
import PodcastMic from '@/inline-svg/PodcastMic.vue';
import VideoIcon from '@/inline-svg/VideoIcon.vue';
import LiveStreamingTwitch from '@/inline-svg/LiveStreamingTwitch.vue';

@Component({
  components: {
    HeroIntro,
    HeroFeatures,
    PodcastMic,
    VideoIcon,
    LiveStreamingTwitch,
  },
})
export default class Podcasts extends Vue {
  private IGOShowLinks = [
    {
      name: 'Spotify',
      link: 'https://open.spotify.com/show/42wbPHs9zD4qSVwEXxVB46',
      buttonType: 'primary',
    },
    {
      name: 'SoundCloud',
      link: 'https://soundcloud.com/igotone',
      buttonType: 'secondary',
    },
    {
      name: 'iTunes',
      link: 'https://podcasts.apple.com/us/podcast/i-got-one/id1441800448',
      buttonType: 'ghost',
    },
  ];

  private WWShowLinks = [
    {
      name: 'Spotify',
      link: 'https://open.spotify.com/show/6GDJ1Ny7vHlshhsZK4XBkn',
      buttonType: 'primary',
    },
    {
      name: 'SoundCloud',
      link: 'https://soundcloud.com/warpworld',
      buttonType: 'secondary',
    },
    {
      name: 'iTunes',
      link: 'https://podcasts.apple.com/us/podcast/warp-world-podcast/id1255246579',
      buttonType: 'ghost',
    },
  ];
}
