

























































import { Component, Vue } from 'vue-property-decorator';
import HeroIntro from '@/components/HeroIntro.vue';
import HeroFeatures from '@/components/HeroFeatures.vue';
import { getFAQ } from '@/services/forum';

@Component({
  components: {
    HeroIntro,
    HeroFeatures,
  },
})
export default class Support extends Vue {
  private allQuestions: any[] = [];

  private forums = {
    buttonText: `Browse the forums`,
    type: 'anchor',
    to: 'https://forum.warp.world/',
  };

  private discord = {
    buttonText: `Get help on Discord`,
    type: 'anchor',
    to: 'https://discord.warp.world/',
  };

  private beforeRouteEnter(to: any, from: any, next: any) {
    next(async (vm: any) => {
      const ccQuestions = await getFAQ(4318);
      const mqQuestions = await getFAQ(6866);
      vm.allQuestions = ccQuestions.concat(mqQuestions);
    });
  }

  private genNamedLink(question: any) {
    return question.toLowerCase().replace(/[^a-zA-Z ]/g, '').replace(/\s/g, '-');
  }

  private sentenceCase(question: any) {
    const firstLetter = question.charAt(0).toUpperCase();
    let casedQuestion = `${firstLetter}${question.slice(1)}`;
    casedQuestion = casedQuestion.replace(/\si\s/g, ' I ');
    return casedQuestion;
  }

  private scrollTo(hashtag: any) {
    setTimeout(() => { location.href = hashtag; }, 1);
  }

  private goSearch(event: any) {
    window.open( `https://forum.warp.world/search?q=${event.target.value}`, '_blank');
  }
}
