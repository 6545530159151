























































































import { Component, Vue } from 'vue-property-decorator';
import HeroIntro from '@/components/HeroIntro.vue';
import HeroFeatures from '@/components/HeroFeatures.vue';
import StaffMember from '@/components/StaffMember.vue';
import SupportWW from '@/components/SupportWW.vue';
import LeadershipAlien from '@/inline-svg/LeadershipAlien.vue';
import StaffCurtainPeople from '@/inline-svg/StaffCurtainPeople.vue';
import CollabNinjas from '@/inline-svg/CollabNinjas.vue';
import { getStaff, getCollaborators } from '../services/team';
import { getPatrons } from '../services/patreon';

@Component({
  components: {
    HeroIntro,
    StaffMember,
    HeroFeatures,
    SupportWW,
    LeadershipAlien,
    CollabNinjas,
    StaffCurtainPeople,
  },
})
export default class Team extends Vue {
  private team: any = {};

  private leadership = [];
  private marketing = [];
  private product = [];
  private collaborators = [];
  private patrons = [];

  private async mounted() {
    const { leadership, departments} = await getStaff();
    this.leadership = leadership;
    this.marketing = departments.marketing;
    this.product = departments.product;

    this.collaborators = await getCollaborators();
    this.patrons = await getPatrons();
  }

}
