import axios from 'axios';

export const getStaff = async () => {
  const response = await axios.get('/api/team/staff');
  return response.data;
};

export const getCollaborators = async () => {
  const response = await axios.get('/api/team/collaborators');
  const protonJon = response.data.filter((collab: any) => collab.twitch_login === 'protonjon')[0];
  const protonJonCopy = Object.assign({}, protonJon);
  protonJonCopy.display_name = 'ProtonJon Chat';
  protonJonCopy.role = 'Super Beta Testing';
  protonJonCopy.avatar = require('@/assets/images/protonjonchat.png');
  const protonJonIndex = response.data.findIndex((collab: any) => collab.twitch_login === 'protonjon');
  response.data.splice(protonJonIndex + 1, 0, protonJonCopy);
  return response.data;
};
